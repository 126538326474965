import React, { useCallback, useContext, useEffect, useState } from "react";
import "./addequipment.scss";

import { useLocation, useNavigate } from "react-router-dom";
import TextArea from "./split-components/TextArea";
import InputField from "./split-components/InputField";
import Checkboxes from "./split-components/Checkboxes";
import DropdownField from "./split-components/DropdownField";
import { AddressInput, MapComponent } from "./split-components/Map&Address";
import UploadImage from "../../components/CommonComponents/upload-image/UploadImage";
import { toast } from "sonner";
import { addNewEquipment, uploadImage } from "../../service/equipmentService";
import { FiChevronLeft } from "react-icons/fi";
import { LoadingContext } from "../../context/LoadingContext";
import UserContext from "../../context/userContext";
import { getCategoryDropdownOptions } from "../../service/categoryService";
import {
  getEquipmentTypeDropDown,
  getMfgYearDropDown,
  getRegionDropDown,
} from "../../service/dropDownService";
import { GetCustomerById } from "../../service/customerService";

const rentalTypeOptions = [
  { id: 0, value: "Hour" },
  { id: 1, value: "Day" },
  { id: 2, value: "Week" },
  { id: 3, value: "Month" },
];

function Addequipment({ equipmentData, onSave, view }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [images, setImages] = useState(Array(3).fill(null));
  const [imageUrls, setImageUrls] = useState(Array(3).fill(null));
  const [categories, setCategories] = useState([]);
  const [equipmentTypeId, setEquipmentTypeId] = useState([]);
  const [region, setRegion] = useState([]);
  const [yearOfMfg, setYearOfMfg] = useState([]);
  const [errors, setErrors] = useState({});
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [hasImage, setHasImage] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formResetKey, setFormResetKey] = useState(Date.now());
  const [coordinates, setCoordinates] = useState(null);
  const [address, setAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shouldLoadComponents, setShouldLoadComponents] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    categoryIds: [],
    equipmentTypeId: "",
    region: "",
    modelNumber: "",
    yearOfMfg: "",
    noOfWorkingHrs: "",
    rentalValue: "",
    rentalType: "",
    discount: "0",
    finalRentalValue: "",
    description: "",
    youtubeLink: "",
    registrationCertificate: false,
    hypoToBank: false,
    insuranceAvailable: false,
    image1Url: "",
    image2Url: "",
    image3Url: "",
    city: "",
    state: "",
    pinCode: "",
    equipmentTypeName: "",
    country: "",
    phoneNumber: "",
    sameAddress: false,
  });

  console.log(imageUrls)

  const { showLoader, hideLoader } = useContext(LoadingContext);
  const context = useContext(UserContext);
  const { user } = context;

  const { equipmentName, selectedCategory, selectedSubcategory, phone } =
    location.state || {};
  // console.log("this is comming from mainpage okay",equipmentName, selectedCategory, selectedSubcategory)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldLoadComponents(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (equipmentData) {
      const regionObj = region.find((r) => r.value === equipmentData.region);
      setFormData({
        name: equipmentData.name || "",
        categoryIds:
          equipmentData.categories.map((category) => category.id) || [],
        equipmentTypeId: equipmentData.equipmentType.id || "",
        region: regionObj ? regionObj.id : "",
        modelNumber: equipmentData.modelNumber || "",
        yearOfMfg: equipmentData.yearOfMfg.toString() || "",
        noOfWorkingHrs: equipmentData.noOfWorkingHrs || "",
        rentalValue: equipmentData.rentalValue || "",
        rentalType: equipmentData.rentalType || "",
        discount: equipmentData.discount.toString() || "0",
        finalRentalValue: equipmentData.finalRentalValue || "",
        description: equipmentData.description || "",
        youtubeLink: equipmentData.youtubeLink || "",
        registrationCertificate: equipmentData.registrationCertificate || false,
        hypoToBank: equipmentData.hypoToBank || false,
        insuranceAvailable: equipmentData.insuranceAvailable || false,
        image1Url: equipmentData.image1Url || "",
        image2Url: equipmentData.image2Url || "",
        image3Url: equipmentData.image3Url || "",
        city: equipmentData.city || "",
        state: equipmentData.state || "",
        pinCode: equipmentData.pinCode || "",
        country: equipmentData.country || "",
      });
      setImageUrls([
        equipmentData.image1Url || null,
        equipmentData.image2Url || null,
        equipmentData.image3Url || null,
      ]);

      setCoordinates({
        lat: equipmentData.latitude,
        lng: equipmentData.longitude,
      });
      setAddress(equipmentData.address || "");
      setMarkerPosition({
        lat: equipmentData.latitude,
        lng: equipmentData.longitude,
      });
      setHasImage(true);
    }
    if (equipmentName || selectedCategory || selectedSubcategory) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: equipmentName || prevFormData.name,
        categoryIds: selectedCategory
          ? [selectedCategory]
          : prevFormData.categoryIds || "",
        equipmentTypeId: selectedSubcategory
          ? selectedSubcategory
          : prevFormData.equipmentTypeId || "",
        phoneNumber: phone ? phone : prevFormData.phoneNumber || "",
      }));
    }

    if (user.loggedInUserPhonenumber) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        phoneNumber: user.loggedInUserPhonenumber,
      }));
    }
  }, [
    equipmentData,
    region,
    equipmentName,
    selectedCategory,
    selectedSubcategory,
    phone,
  ]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Show the loader
        showLoader();
  
        // Fetch customer data using the API
        const response = await GetCustomerById(user.loggedInUserId);
  
        // Check if the response is successful and update the formData
        if (response) {
          console.log(response)
          const { state, city, addressLine1, pincode, country } = response;
          
          setAddress(addressLine1);
          setFormData((prevFormData) => ({
            ...prevFormData,
            state: state || "",
            city: city || "",
            pinCode: pincode || "",
            country: country || "",
          }));
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        // Hide the loader
        hideLoader();
      }
    };
  
    // Call the API when formData.sameAddress becomes true
    if (formData.sameAddress) {
      fetchUserData();
    }
  }, [formData.sameAddress]);
  

  

  const handleImageSelected = async (index, image) => {
    setImages((prevImages) => {
      const updatedImages = [
        ...prevImages.slice(0, index),
        image,
        ...prevImages.slice(index + 1),
      ];

      const hasAnyImage = updatedImages.some((img) => img !== null);
      setHasImage(hasAnyImage);

      if (hasAnyImage) {
        setErrors((prevErrors) => ({ ...prevErrors, image: "" }));
      }

      return updatedImages;
    });

    try {
      const imageUrl = await uploadImage(image);
      console.log(imageUrl)
      setImageUrls((prevUrls) => [
        ...prevUrls.slice(0, index),
        imageUrl,
        ...prevUrls.slice(index + 1),
      ]);
    } catch (error) {
      console.error("There was an error uploading the image!", error);
    }
  };

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    if (id === "description" && value.length > 250) {
      return;
    }

    let updatedFormData = { ...formData, [id]: fieldValue };

    if (id === "rentalValue" || id === "discount") {
      const rentalValue = parseFloat(
        id === "rentalValue" ? value : formData.rentalValue
      );
      const discount = parseFloat(
        id === "discount" ? value : formData.discount
      );
      const finalRentalValue = rentalValue - rentalValue * (discount / 100);
      updatedFormData = {
        ...updatedFormData,
        finalRentalValue: finalRentalValue.toFixed(2),
      };
    }

    setFormData(updatedFormData);

    setErrors((prevErrors) => {
      const { [id]: removedError, ...restErrors } = prevErrors;
      return restErrors;
    });

    if (id === "description") {
      setDescriptionCount(value.length);
    }
  };

  const handleDropdownChange = (id, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name) formErrors.name = "Name is required";
    if (formData.categoryIds.length === 0)
      formErrors.categoryIds = "Category is required";
    if (!formData.equipmentTypeId)
      formErrors.equipmentTypeId = "equipmentTypeId is required";
    if (!formData.phoneNumber)
      formErrors.phoneNumber = "phoneNumber is required";
    if (!formData.modelNumber) formErrors.modelNumber = "Model No is required";
    if (!formData.yearOfMfg)
      formErrors.yearOfMfg = "Year of Manufacture is required";
    if (!address) formErrors.address = "address is required";
    if (!formData.noOfWorkingHrs)
      formErrors.noOfWorkingHrs = "Working Hours is required";
    if (!formData.rentalValue)
      formErrors.rentalValue = "Rental Value is required";
    if (!hasImage) formErrors.image = "At least one image is required";
    // if (!formData.city) formErrors.city = 'City is required';
    // if (!formData.state) formErrors.state = 'State is required';
    // if (!formData.pinCode) formErrors.pincode = 'Pincode is required';
    if (!formData.country) formErrors.country = "Country is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const fetchDropdownData = useCallback(async () => {
    try {
      const [
        categoriesResponse,
        subCategoriesResponse,
        regionResponse,
        yearOfMfgResponse,
      ] = await Promise.all([
        getCategoryDropdownOptions(),
        getEquipmentTypeDropDown(),
        getRegionDropDown(),
        getMfgYearDropDown(),
      ]);

      setCategories(categoriesResponse);
      setEquipmentTypeId(subCategoriesResponse);
      setRegion(regionResponse);
      setYearOfMfg(yearOfMfgResponse);
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitted(true);
    if (!validateForm()) {
      return;
    }

    showLoader();

    const formDataToSend = {
      ...formData,
      supplierId: user.loggedInUserId,
      latitude: coordinates?.lat || "",
      longitude: coordinates?.lng || "",
      address,
      image1Url: imageUrls[0] || "",
      image2Url: imageUrls[1] || "",
      image3Url: imageUrls[2] || "",
    };

    if (equipmentData) {
      formDataToSend.id = equipmentData.id;
    }

    try {
      let response;

      if (equipmentData) {
        response = await onSave(formDataToSend);
        // toast.success('Equipment Updated Successfully');
      } else {
        response = await addNewEquipment(formDataToSend);
        toast.success("Equipment Created Successfully");
      }

      navigate("/dashboard/equipments-list");
      console.log("Form submitted successfully:", response.data);
      setFormData({
        name: "",
        categoryIds: [],
        equipmentTypeId: "",
        region: "",
        modelNumber: "",
        yearOfMfg: "",
        noOfWorkingHrs: "",
        rentalValue: "",
        rentalType: "",
        discount: "0",
        finalRentalValue: "",
        description: "",
        youtubeLink: "",
        registrationCertificate: false,
        hypoToBank: false,
        insuranceAvailable: false,
        image1Url: "",
        image2Url: "",
        image3Url: "",
        city: "",
        state: "",
        pinCode: "",
        country: "",
        phoneNumber: "",
        sameAddress: "",
      });
      setImages(Array(3).fill(null));
      setImageUrls(Array(3).fill(null));
      setFormResetKey(Date.now());
      setDescriptionCount(0);
      setHasImage(false);
      setMarkerPosition(null);
      // setLoading(false);
      if (equipmentData) {
        toast.success("Equipment Updated Successfully");
        hideLoader();
        navigate("/dashboard/equipments-list");
      } else {
        toast.success("Equipment Created Successfully");
        hideLoader();
        // navigate('/dashboard/equipments-list');
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (!equipmentData) {
        toast.error("Error creating equipment: " + error.message);
      }
      hideLoader();
    }
  };

  useEffect(() => {
    fetchDropdownData();
  }, [fetchDropdownData]);

  const fields = [
    {
      id: "registrationCertificate",
      checked: formData.registrationCertificate,
      label: "Registration Certificate",
    },
    // {
    //   id: 'hypoToBank',
    //   checked: formData.hypoToBank,
    //   label: 'Hypo to Bank',
    // },
    {
      id: "insuranceAvailable",
      checked: formData.insuranceAvailable,
      label: "Insurance Available",
    },
  ];

  const sameAddressCheckbox = [
    {
      id: "sameAddress",
      checked: formData.sameAddress,
      label: "Same as Registered Address",
    },
  ];

  return (
    <div className="add-equipment-container">
      {equipmentData ? (
        <button className="back-btn" onClick={() => navigate(-1)}>
          <FiChevronLeft size={16} />
          <p>Back to Results</p>
        </button>
      ) : (
        ""
      )}

      <h2>
        {onSave
          ? "Edit Subscription"
          : view
          ? "View Subscription"
          : "Add Subscription"}
      </h2>
      <form onSubmit={handleSubmit}>
        {/* <div className='input-field'>

        </div> */}
        <InputField
          label="Equi. Display Name"
          id="name"
          value={formData.name}
          error={errors.name}
          onChange={handleChange}
          readOnly={view}
        />
        <div className="split-input">
          <DropdownField
            id="categoryIds"
            label="category"
            options={categories}
            value={formData.categoryIds}
            onChange={handleDropdownChange}
            error={errors.categoryIds}
            multiple={true}
            disable={view}
            reset={formResetKey}
          />
          <DropdownField
            id="equipmentTypeId"
            label="Equipment Type"
            options={equipmentTypeId}
            value={formData.equipmentTypeId}
            onChange={handleDropdownChange}
            error={errors.equipmentTypeId}
            reset={formResetKey}
            disable={view}
            otherValue={true}
          />
        </div>

        {formData.equipmentTypeId === "other" && (
          <InputField
            label="Specify Other"
            id="other"
            value={formData.equipmentTypeName}
            onChange={handleChange}
            readOnly={view}
          />
        )}
        <div className="split-input">
          {/* <DropdownField
          label='Region'
          id='region'
          value={formData.region}
          options={region}
          error={errors.region}
          onChange={handleDropdownChange}
          reset={formResetKey}
          disable={view}
        /> */}
          <InputField
            label="Phone No"
            id="phoneNumber"
            value={formData.phoneNumber}
            // error={errors.phoneNumber}
            onChange={handleChange}
            readOnly={view}
          />

          <InputField
            label="Model Name"
            id="modelNumber"
            value={formData.modelNumber}
            error={errors.modelNumber}
            onChange={handleChange}
            readOnly={view}
          />
        </div>
        <div className="split-input">
          <DropdownField
            label="Model Year"
            id="yearOfMfg"
            value={formData.yearOfMfg}
            options={yearOfMfg}
            error={errors.yearOfMfg}
            onChange={handleDropdownChange}
            reset={formResetKey}
            disable={view}
          />
          <InputField
            label="Capacity"
            id="noOfWorkingHrs"
            value={formData.noOfWorkingHrs}
            error={errors.noOfWorkingHrs}
            onChange={handleChange}
            type="number"
            readOnly={view}
          />
        </div>
        <div className="split-input">
          <InputField
            label="Rental Value"
            id="rentalValue"
            value={formData.rentalValue}
            error={errors.rentalValue}
            onChange={handleChange}
            type="number"
            readOnly={view}
          />
          <InputField
            label="Discount (%)"
            id="discount"
            value={formData.discount}
            error={errors.discount}
            onChange={handleChange}
            type="number"
            readOnly={view}
          />
          <InputField
            label="Final Value"
            id="finalRentalValue"
            value={formData.finalRentalValue}
            error={errors.finalRentalValue}
            onChange={handleChange}
            type="number"
            disabled
            readOnly={view}
          />
        </div>

        <div className="split-input">
          <DropdownField
            id="rentalType"
            label="Rental Type"
            options={rentalTypeOptions}
            value={formData.rentalType}
            onChange={handleDropdownChange}
            // error={errors.categoryIds}
            disable={view}
            reset={formResetKey}
          />
        </div>

        <div className="input-field checkboxes">
          <Checkboxes fields={fields} onChange={handleChange} readOnly={view} />
        </div>

        <div className="input-field">
          <TextArea
            label="Equipment Description"
            id="description"
            value={formData.description}
            error={errors.description}
            onChange={handleChange}
            maxLength={250}
            count={descriptionCount}
            readOnly={view}
          />
        </div>
        <InputField
          label="YouTube Link"
          id="youtubeLink"
          value={formData.youtubeLink}
          error={errors.youtubeLink}
          onChange={handleChange}
          readOnly={view}
        />
        <div className="address-field">
          <div className="left">
            <div className="input-field">
              <label className="input-label" htmlFor="imagetitle">
                Images
              </label>

              <div className="image-input-container">
                {[0, 1, 2].map((index) => (
                  <UploadImage
                    key={index}
                    index={index}
                    initialImage={imageUrls[index]}
                    onImageSelected={handleImageSelected}
                    showError={isSubmitted && !hasImage}
                    hasImage={hasImage}
                    readOnly={view}
                  />
                ))}
              </div>
            </div>

            {shouldLoadComponents ? (
              <MapComponent
                coordinates={coordinates}
                setAddress={setAddress}
                setCity={(value) => setFormData({ ...formData, city: value })}
                setState={(value) => setFormData({ ...formData, state: value })}
                setPincode={(value) =>
                  setFormData({ ...formData, pincode: value })
                }
                setCountry={(value) =>
                  setFormData({ ...formData, country: value })
                }
                setCoordinates={setCoordinates}
                markerPosition={markerPosition}
                setMarkerPosition={setMarkerPosition}
                error={errors.coordinates}
              />
            ) : (
              <div className="loading-placeholder">
                Loading Address and Map...
              </div>
            )}
          </div>
        </div>

        <div className="input-field checkboxes">
          <Checkboxes
            fields={sameAddressCheckbox}
            onChange={handleChange}
            readOnly={view}
          />
        </div>

        {shouldLoadComponents ? (
          <AddressInput
            address={address}
            setAddress={setAddress}
            city={formData.city}
            setCity={(value) => setFormData({ ...formData, city: value })}
            state={formData.state}
            setState={(value) => setFormData({ ...formData, state: value })}
            pincode={formData.pinCode}
            setPincode={(value) => setFormData({ ...formData, pinCode: value })}
            country={formData.country}
            setCountry={(value) => setFormData({ ...formData, country: value })}
            setFormData={setFormData}
            error={errors}
            setCoordinates={setCoordinates}
            setMarkerPosition={setMarkerPosition}
            readOnly={view}
          />
        ) : (
          <div className="loading-placeholder">Loading Address and Map...</div>
        )}

        {!view && Object.keys(errors).length > 0 && (
          <div className="error-text">Please Fill The Highlighted Fields</div>
        )}
        {view ? (
          ""
        ) : (
          <div className="btns-container">
            <button
              type="submit"
              disabled={loading}
              className="save-btn"
              onClick={handleSubmit}
            >
              {loading ? "SAVING..." : "SAVE"}
            </button>
            <button
              type="button"
              className="cancel-btn"
              onClick={() => navigate(-1)}
            >
              CANCEL
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default Addequipment;
